<template>
    <div class="container nav z-900 w-full flex items-center justify-center h-full">
        <nav v-if="dataRender?.menus && dataRender?.menus.length > 0" class="h-full">
            <!-- <a href="#" class="mobile-menu-trigger">Open mobile menu</a> -->
            <ul ref="header" class="menu menu-bar activeMenu h-full">
                <li
                    v-for="(item, index) in dataRender?.menus"
                    :key="item?.id"
                    :class="item?.childs && item?.childs.length > 0 ? 'relative' : ''"
                    class="flex items-center text-primary-50 hover:text-primary-100 text-bw-07 duration-200 cursor-pointer"
                >
                    <nuxt-link
                        v-if="!item?.mega && item?.childs && item?.childs.length == 0"
                        :to="item?.url ?? '/'"
                        :class="item.mega || (item?.childs && item?.childs.length > 0) ? 'hasChild' : ''"
                        class="menu-link py-8 font-medium lg:text-sm xl:text-base flex items-center gap-0.5 h-full"
                    >
                        <p>{{ item?.name }}</p>
                        <span
                            :class="item.mega || (item?.childs && item?.childs.length > 0) ? 'block' : 'hidden'"
                            class="i-ic:twotone-keyboard-arrow-down text-lg"
                        ></span>
                    </nuxt-link>
                    <div
                        v-else
                        :class="item.mega || (item?.childs && item?.childs.length > 0) ? 'hasChild' : ''"
                        class="menu-link py-8 font-medium lg:text-sm xl:text-base flex items-center gap-0.5 h-full"
                    >
                        <p>{{ item?.name }}</p>
                        <span
                            :class="item.mega || (item?.childs && item?.childs.length > 0) ? 'block' : 'hidden'"
                            class="i-ic:twotone-keyboard-arrow-down text-lg"
                        ></span>
                    </div>
                    <ul
                        v-if="item.mega"
                        class="hidden lg:flex justify-center mega-menu px-6 pt-6 pb-10 shadow-md lg:b-1 lg:b-solid lg:bt-bw-08 min-h-[calc(50vh-180px)] max-h-[calc(100vh-180px)] gap-5 overflow-auto"
                    >
                        <div class="flex-1 relative mega-menu--multiLevel lg:px-3 w-4/12 max-w-105">
                            <ul
                                v-if="dataHasBrand && dataHasBrand.length > 0"
                                v-for="brand in dataHasBrand"
                                class="pb-5"
                            >
                                <p class="xl:text-lg line-clamp-1 font-semibold text-blacks-100">{{ brand?.title }}</p>
                                <ul
                                    :class="brand?.product_categories.length > 8 ? 'grid-cols-2' : 'grid-cols-1'"
                                    class="text-sm text-blacks-50 grid mt-2"
                                >
                                    <li class="" v-for="item in brand?.product_categories">
                                        <nuxt-link :to="item.url" class="py-2 hover:text-blacks-100 duration-200">
                                            {{ item?.title }}
                                        </nuxt-link>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                        <ul
                            v-if="dataRender?.collections && dataRender?.collections.length > 0"
                            class="flex-1 flex flex-col relative mega-menu--multiLevel lg:px-3 w-4/12 max-w-105"
                        >
                            <p class="xl:text-lg line-clamp-1 font-semibold text-blacks-100">Bộ sưu tập</p>
                            <ul
                                :class="dataRender?.collections > 8 ? 'grid-cols-2' : 'grid-cols-1'"
                                class="text-sm text-blacks-50 grid mt-2"
                            >
                                <li v-for="item in dataRender?.collections">
                                    <nuxt-link :to="item.url" class="py-2 hover:text-blacks-100 duration-200">
                                        {{ convert(item?.title) }}
                                    </nuxt-link>
                                </li>
                            </ul>
                            <nuxt-link to="/bo-suu-tap" class="underline text-blacks-70 text-10px mt-auto"
                                >Tất cả bộ sưu tập</nuxt-link
                            >
                        </ul>
                        <div
                            class="b-l-2 b-l-blacks-200 flex-1 relative mega-menu--multiLevel w-4/12 max-w-105 pl-10 pr-3"
                        >
                            <p class="xl:text-lg line-clamp-1 font-semibold text-blacks-100">Bộ sưu tập mới ra mắt</p>
                            <nuxt-link :to="itemBanner?.btn_link" class="aspect-16/10 mt-4 overflow-hidden group">
                                <NuxtImg
                                    :src="getThumbnail(itemBanner?.image_bg, 600, true)"
                                    width="600"
                                    sizes="sm:100vw md:50vw lg:600px "
                                    loading="lazy"
                                    class="w-full h-full object-cover group-hover:scale-105 duration-400"
                                    :placeholder="[50, 25, 75, 5]"
                                />
                            </nuxt-link>
                        </div>
                    </ul>
                    <ul
                        v-if="item?.childs && item?.childs.length > 0"
                        class="w-max lg:px-5 lg:py-3 absolute top-full left-1/2 bg-white flex flex-col gap-3 b-1 b-solid b-bw-08 shadow-md transform -translate-x-1/2 !scale-100"
                    >
                        <li v-for="(itemChild, index) in item?.childs" :key="index" class="block space-y-1">
                            <component
                                :is="linkItem(itemChild?.url)"
                                :to="itemChild?.url || '/'"
                                class="text-blacks-70 text-sm hover:text-blacks-100 p-2 block"
                            >
                                {{ itemChild?.name }}
                            </component>
                        </li>
                    </ul>
                </li>
                <!-- <li class="mobile-menu-header">
                    <a href="/home" class="">
                        <span>Home</span>
                    </a>
                </li> -->
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
import { convert } from 'html-to-text'

const props = defineProps({
    dataRender: {
        type: Object as any
    },
    bannerNewCollection: {
        type: Object as any
    },
    dataHasBrand: {
        type: Object as any
    }
})
const itemBanner = computed(() => props?.bannerNewCollection?.banners?.banner_items[0])

const linkItem = (item: any) => {
    if (!item) return 'span'
    else {
        if (item?.includes('http')) return 'a'
        return resolveComponent('NuxtLink')
    }
}
const currentRoute = useRoute()
const header = ref()
watch(
    () => currentRoute.path,
    (newValue: string) => {
        if (newValue) {
            header.value?.classList.remove('activeMenu')

            setTimeout(() => {
                header.value?.classList.add('activeMenu')
            }, 500)
        }
    }
)
</script>

<style>
nav ul,
nav li {
    list-style: none;
    margin: 0;
}
nav a {
    display: block;
    text-decoration: none;
    line-height: 24px;
}

.menu-bar {
    background: #ffffff;
    display: flex;
    z-index: 1000;
    @apply lg:gap-5 xl:gap-8;
}
.menu-link {
    background: #ffffff;
    transition: background 0.2s, color 0.2s;
    position: relative;
    z-index: 1;
}

.mega-menu-header {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
}
.mega-menu {
    background: #ffffff;
    z-index: 10;
}
.mega-menu--multiLevel ul {
    flex-direction: column;
}

@media all and (min-width: 951px) {
    .nav {
    }
    .menu.menu-list {
        @apply b-l-2 b-gray-100;
    }
    .menu .hasChild ~ ul {
        display: none;
    }

    .menu-bar > li a + ul {
        display: none;
    }

    .menu-bar.activeMenu > li:hover > .hasChild ~ ul {
        display: flex;
        transform-origin: top;
        animation: dropdown 0.25s cubic-bezier(0.57, 0.21, 0.69, 0.8);
    }

    /*
     .menu-bar > li:hover >  ul {
        display: flex;
        transform-origin: top;
        animation: dropdown 0.2s ease-out;
    }
    .menu-bar > li:hover > .hasChild ~ ul {
        display: flex;
    }
    */
    .menu-bar > li > .hasChild:hover,
    .menu-bar > li:hover > .hasChild,
    .menu-bar > li:hover > a {
    }
    .menu-bar > li > .hasChild:hover:after,
    .menu-bar > li:hover > .hasChild:after,
    .mega-menu {
        position: absolute;
        left: 0;
        width: 100%;
        top: 100%;
    }
    .mega-menu::after {
        content: '';

        @apply absolute z-1 inset-0 w-full h-10 bg-transparent z-100 transform -translate-y-7;
    }
    .mega-menu:hover {
        display: flex;
    }

    .mega-menu--multiLevel li:hover > .hasChild,
    .mega-menu--multiLevel li:hover > span,
    .mega-menu--multiLevel li.activeMenu > .hasChild,
    .mega-menu--multiLevel li.activeMenu > span {
        @apply text-primary bg-gray-100 rounded-lg;
    }

    .mega-menu--multiLevel > li > a {
        max-width: 50%;
    }
    .mega-menu--multiLevel > li {
        width: 100%;
    }

    .mega-menu--multiLevel > li > .hasChild + ul {
        left: 53%;
        width: 43%;
    }
    .mega-menu--multiLevel > li > .hasChild ~ ul ul {
        width: 100%;
        left: 100%;
        padding-left: 20px;
    }
    .mega-menu--multiLevel li:hover > .hasChild ~ ul {
        display: block;
        transform-origin: left;
        animation: flyout 0.2s ease-out;
    }
    .mega-menu--multiLevel li:hover > .hasChild ~ ul {
        display: block;
    }
    .mega-menu--multiLevel li.activeMenu > .hasChild ~ ul {
        display: block;
        transform-origin: left;
        animation: flyout 0.2s ease-out;
    }
    .mega-menu--multiLevel li.activeMenu > .hasChild ~ ul {
        display: block;
    }
    .mega-menu--multiLevel li:hover > .hasChild,
    .mega-menu--multiLevel li:hover > .hasChild,
    .mega-menu--multiLevel li:hover > a,
    .mega-menu--multiLevel li:hover > a {
    }
    .mega-menu--multiLevel .hasChild ~ ul,
    .mega-menu--multiLevel .hasChild {
    }
    .mega-menu--multiLevel .hasChild ~ ul:hover {
        display: block;
    }
    .mega-menu--multiLevel .hasChild ~ ul {
        position: absolute;
        top: 0;
        height: 100%;
    }
    .mega-menu--flat > * {
        flex: 1;
    }
    .mobile-menu-trigger,
    .mobile-menu-header,
    .mobile-menu-back-item {
        display: none;
    }
    .menu-link.hasChild + .menu-list {
    }
    .mega-menu.mega-menu--multiLevel a {
        @apply py-2;
    }
    .mega-menu--multiLevel {
        @apply relative;
    }
    .mega-menu li > a {
        @apply py-2 lg:text-sm  xl:text-base;
    }
}
@media all and (max-width: 950px) {
    .nav {
        padding: 20px;
    }
    .mobile-menu-trigger,
    .mobile-menu-header,
    .mobile-menu-back-item {
        display: block;
    }
    .mobile-menu-trigger {
        background: #177e89;
        color: #ffffff;
        border: 0;
        padding: 10px;
        font-size: 1.2em;
        border-radius: 4px;
    }
    .mobile-menu-header {
        order: -1;
        background: grey;
    }
    .mobile-menu-header a {
        padding: 20px 25px;
        color: #ffffff;
        visibility: visible;
    }
    .menu-bar {
        flex-direction: column;
        position: fixed;
        top: 0;
        left: -100%;
        height: 100vh;
        width: 100%;
        overflow-x: hidden;
        transition: left 0.3s;
        box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
        @apply px-5;
    }
    .menu-bar > li > .hasChild ~ ul {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        position: absolute;
        left: 100%;
        top: 0;
        max-height: 100vh;
        width: 100%;
        transition: left 0.3s;
    }
    .menu-bar > li > .hasChild ~ ul > li > .hasChild {
        font-size: 1.2em;
    }
    .menu-bar > li > .hasChild ~ ul > li > .hasChild ~ ul a {
        padding-left: 40px;
    }
    .menu-bar > li > .hasChild ~ ul > li > .hasChild ~ ul > li > .hasChild ~ ul a {
        padding-left: 80px;
    }
    .menu-bar > li > .hasChild ~ ul .hasChild {
        color: #2a2a2a;
    }
    .menu-bar > li > .hasChild ~ ul .hasChild:after {
        content: '+';
        background: none;
        position: absolute;
        right: 20px;
        font-size: 1em;
        font-weight: normal;
        transform: scale(1.2);
        line-height: 1;
    }
    .menu-bar > li > .hasChild ~ ul .hasChild ~ ul {
        max-height: 0px;
        transform-origin: top;
        transform: scaleY(0);
        transition: max-height 0.1s;
    }
    .mega-menu-content {
        padding: 20px 25px;
    }
    .mobile-menu-back-item {
        order: -1;
    }
    .mobile-menu-back-item a {
        background: #d9d9d9;
        color: #2a2a2a;
        pointer-events: none;
    }
    .mobile-menu-back-item a:before {
        content: '';
        width: 14px;
        height: 12px;
        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowLeft.svg#default');
        background-size: 14px;
        margin-right: 10px;
        display: inline-block;
    }
    .mobile-menu-trigger:focus ~ ul {
        left: 0;
    }
    .menu-bar:hover,
    .menu-bar:focus-within {
        left: 0;
    }
    .menu-bar > li > .hasChild:focus ~ ul {
        left: 0;
    }
    .menu-bar > li > .hasChild ~ ul {
    }
    .menu-bar > li > .hasChild ~ ul:hover,
    .menu-bar > li > .hasChild ~ ul:focus-within {
        left: 0;
    }
    .menu-bar > li > .hasChild ~ ul .hasChild:focus ~ ul {
        max-height: 500px;
        animation: dropdown 0.3s forwards;
    }
    .menu-bar > li > .hasChild ~ ul li:focus-within > .hasChild ~ ul {
        max-height: 500px;
        transform: scaleY(1);
    }
    .menu-bar > li:focus-within ~ .mobile-menu-header a {
        visibility: hidden;
    }

    .menu-link {
        @apply w-full;
    }
    .menu-link.mega-menu-link {
        @apply px-5 py-3;
    }
}
@media all and (max-width: 950px) and (hover: none) {
    .mobile-menu-trigger:hover ~ ul {
        left: 0;
    }
    .menu-bar > li > .hasChild:hover ~ ul {
        left: 0;
    }
    .menu-bar > li > .hasChild ~ ul:hover {
        left: 0;
    }
    .menu-bar > li > .hasChild ~ ul .hasChild:hover ~ ul {
        max-height: 500px;
        animation: dropdown 0.3s forwards;
    }
    .menu-bar > li > .hasChild ~ ul .hasChild ~ ul:hover {
        max-height: 500px;
        transform: scaleY(1);
    }
    .menu-bar > li:hover ~ .mobile-menu-header a {
        visibility: hidden;
    }
}
@keyframes dropdown {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scaleY(1);
    }
}
@keyframes flyout {
    0% {
        opacity: 0;
        transform: scaleX(0);
    }
    100% {
        opacity: 1;
        transform: scaleX(1);
    }
}
.main-menu-mobile-ul {
    @apply divide-y;
}
.main-menu-mobile > div {
    @apply container;
}
.item-menu-mobile p,
.item-menu-mobile span {
    @apply text-16px;
}
.item-menu-mobile .icon-drop-down {
    @apply transform -rotate-90;
}
.item-menu-mobile .icon-come-back {
    @apply transform rotate-360 text-30px text-primary;
}
.item-menu-mobile:hover {
    @apply !bg-bw-08;
}
.item-menu-mobile:hover a,
.item-menu-mobile:hover span {
    @apply !text-primary;
}
.menu.menu-bar > li > a.router-link-active,
.menu.menu-bar > li > a.router-link-active ~ span {
    @apply text-primary-100;
}
.main-menu-mobile {
    @apply p-0;
}
.main-menu-mobile ul {
    @apply divide-y;
}

@media (max-width: 1279px) {
    .menu-link {
        @apply text-xs;
    }
    .mega-menu li > a {
        @apply pb-3 text-xs;
    }
    .mega-menu li span {
        @apply mt-0;
    }
}
</style>
